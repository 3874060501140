import axios from 'axios'
import store from "@/store";
import router from "@/router";
import {useToast} from "vue-toastification";
import {get_cookies} from "@/util";

const toast = useToast();

const resp_success = resp => {
    const token = get_cookies()['X-Auth'];
    if (token)
        store.dispatch('auth/refresh_token_and_timeouts', token)
    return resp;
}
const resp_err = err => {
    if (store.state.auth.data && store.state.auth.data.exp * 1000 < Date.now()) {
        store.dispatch('auth/logout')
        router.replace({
            name: 'login',
            query: router.currentRoute.value.path !== '/' ? {path: router.currentRoute.value.path} : undefined
        });
    }
    if (Math.floor(err.response.status / 100) === 4 && err.response.data.error_message) {
        toast.error(err.response.data.error_message);
    } else {
        toast.error("ERROR - Contact rick.gentry@crispin.com for assistance.") // TODO update contact info for Connect portal
        console.log(err);
    }
    throw err;
}

export const auth_api = axios.create({headers: {'X-Source': `${window.location.protocol}${window.location.host}`}});
auth_api.interceptors.response.use(resp_success, resp_err);

export const blocking_auth_api = axios.create({headers: {'X-Source': `${window.location.protocol}${window.location.host}`}});
blocking_auth_api.interceptors.request.use(req => {
    store.commit('get_processing_lock');
    return req;
});
blocking_auth_api.interceptors.response.use(resp => {
    store.commit('release_processing_lock');
    return resp_success(resp);
}, err => {
    store.commit('release_processing_lock');
    resp_err(err);
});