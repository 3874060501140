import {createLogger, createStore} from 'vuex'
import auth from '@/store/modules/auth'
import {blocking_auth_api} from "@/axios";

const debug = process.env.NODE_ENV !== 'production'

const state = () => ({
    processing: 0,
})

const getters = {}

const actions = {
}

const mutations = {
    get_processing_lock(state) {
        state.processing += 1
    },
    release_processing_lock(state) {
        state.processing -= 1
    },
}

export default createStore({
    modules: {
        auth
    },
    state,
    actions,
    getters,
    mutations,
    strict: debug,
    plugins: debug ? [createLogger()] : []
})