import {createApp} from 'vue'

import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, fa} from 'vuetify/iconsets/fa-svg'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

import App from './App.vue'

import store from "@/store";
import router from "@/router";
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";

import "@/assets/theme.less";

document.title = 'Crispin Data+Analytics / Creator';

const app = createApp(App)
app.use(store);
app.use(router);
app.use(Toast, {
    position: POSITION.TOP_CENTER,
    maxToasts: 3,
    hideProgressBar: true,
    pauseOnHover: true,
    showCloseButtonOnHover: true
});
app.component('font-awesome-icon', FontAwesomeIcon)
library.add(fas)
library.add(far)
library.add(fab)
const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    }
})
app.use(vuetify)
app.config.unwrapInjectedRef = true;

Promise.all([
    store.dispatch('auth/init')
]).then(() => app.mount('#app'));
