<template>
  <v-app theme="dark">
    <v-main class="d-flex">
      <v-app-bar v-if="token">
        <v-app-bar-title>Crispin Connect</v-app-bar-title>
        <template #append>
          <v-btn @click="logout" class="ga-2">
            <div class="d-flex ga-2">Logout
              <v-icon icon="fa fa-sign-out"/>
            </div>
          </v-btn>
        </template>
      </v-app-bar>
      <Suspense>
        <router-view/>
      </Suspense>
      <Loading/>
    </v-main>
  </v-app>
</template>

<script setup>
import {useStore} from "vuex";

import Loading from "@/components/Loading";
import {computed} from "vue";

const store = useStore();
const token = computed(() => store.state.auth.token);

function logout() {
  store.dispatch('auth/logout')
}

</script>


<style lang="less">
</style>