export function get_cookies() {
    return document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
    }, {})
}

export function max_string(str, max_len) {
    if (str.length <= max_len)
        return str
    return str.substring(0, max_len) + '...'
}

export function file_to_data_url(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
    })
}
